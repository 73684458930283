import React from "react";
import Axios from "axios";
import Url from "../../tools/url";
import "./index.css";

class SendBox extends React.Component {
  constructor(prop) {
    super(prop);
    console.log(prop);
  }

  sendMsg = async () => {
    let receiverName = this.refs["receiverName"].value;
    let content = this.refs["content"].value;
    let userList;
    let receiverId;

    // 获取结接收者列表
    let res = await Axios.get(`${Url.UserURL()}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("iris-token")}`
      }
    });
    if (res.status === 200) {
      userList = res.data;
      console.log("获取用户列表成功 => ", userList);
    } else {
      alert("发送失败：获取用户列表失败。");
      return;
    }

    // 获取接受者Id
    if (receiverName === "") {
      receiverId = 0;
    } else {
      userList.forEach(item => {
        if (item.username === receiverName) {
          receiverId = item.id;
          console.log("成功获取接受者信息 => ", item);
        }
      });
    }

    // 发送消息
    if (receiverId === undefined) {
      alert(`发送失败：无法找到用户名为${receiverName}的接受者`);
      return;
    }
    if (content === "") {
      alert(`发送失败：你不能发送空消息`);
      return;
    }

    console.log(`正在给id为=>${receiverId}的用户发送消息=>${content}`);
    Axios.post(
      `${Url.MessageURL()}`,
      {
        receiverId: receiverId,
        content: content
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("iris-token")}`
        }
      }
    )
      .then(res => {
        console.log("发送成功 => ", res);
        this.refs["content"].value = "";
      })
      .catch(err => {
        console.log("发送失败 => ", err.response.data);
      });
  };

  logout = () => {
    console.log("正在注销用户");
    console.log("正在清除localStroage");
    localStorage.removeItem("iris-token");
    console.log("清除localStroage成功，完成注销");
    window.location.reload();
  };
  render() {
    return (
      <div className="sendbox">
        <div className="sendbox-sendto">
          <button className="sendbox-sendto-sender" onClick={this.logout}>
            {this.props.userInfo.username}
          </button>
          <div className="sendbox-sendto-charto">to</div>
          {/* <div className="sendbox-sendto-receiver">{this.state.receiverName}</div> */}
          <input
            className="sendbox-sendto-receiver"
            placeholder="All"
            ref="receiverName"
          />
        </div>
        <div className="sendbox-msg">
          <input
            className="sendbox-msg-input"
            placeholder="请输入消息内容"
            ref="content"
          ></input>
          <button className="sendbox-msg-button" onClick={this.sendMsg}>
            发送
          </button>
        </div>
      </div>
    );
  }
}

export default SendBox;
