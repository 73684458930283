import React from "react"
import Moment from "moment"
import "./index.css"
class Message extends React.Component{
  constructor(prop){
    super(prop)
  }

  render(){
    return (
      <div className="message">
        <div className="message-time">
          {Moment.unix(this.props.msgInfo.sendTime).format("YYYY-MM-DD HH:mm")}
        </div>
        <div className={this.props.msgInfo.private?"message-sender private":"message-sender"}>
          {this.props.msgInfo.senderName} :
        </div>
        <div className="message-content">
          <span className="message-content-content">{this.props.msgInfo.content} </span>
        </div>
      </div>
    )
  }
}

export default Message