import React from "react";
import Message from "../Message";
import Axios from "axios";
import Moment from "moment";
import Url from "../../tools/url";
import "./index.css";
class MsgContent extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      logged: false,
      timerId: 0,
      lastMsgId: 0,
      msgList: []
    };
  }
  componentDidUpdate() {
    this.refs["msg-container"].scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
  }
  componentWillMount = () => {
    let timerId = setInterval(() => {
      if (this.state.logged) {
        Axios.get(`${Url.MessageURL()}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("iris-token")}`
          },
          params: {
            beginTime: Moment()
              .subtract(2, "days")
              .format("X"),
            endTime: 0,
            beginId: this.state.lastMsgId
          }
        })
          .then(res => {
            // console.log('获取消息成功 => ',res)
            let currLastMsgId = this.state.lastMsgId;
            console.log(" => ", currLastMsgId);
            let resMsgList = res.data;
            let resMsgLen = resMsgList.length;
            let mewMsgList = [...this.state.msgList];
            resMsgList.forEach(element => {
              mewMsgList.push(element);
            });
            this.setState({
              logged: true,
              msgList: mewMsgList,
              lastMsgId:
                resMsgLen === 0 ? currLastMsgId : resMsgList[resMsgLen - 1].id
            });
          })
          .catch(err => {
            console.log("消息组件获取消息失败 => ", err.response.data);
            this.setState({
              logged: false
            });
            switch (err.response.status) {
              case 401:
                alert("登录状态失效，请重新登录！");
                this.props.history.push("/signin");
                clearTimeout(this.state.timerId);
                break;
            }
          });
      }
    }, 700);

    this.setState({
      timerId: timerId
    });
  };
  componentWillUnmount = () => {
    clearTimeout(this.state.timerId);
  };
  render() {
    return (
      <div className="msg-container" ref="msg-container">
        {this.state.msgList.map((item, index) => (
          <Message key={index} msgInfo={item}></Message>
        ))}
      </div>
    );
  }
}

export default MsgContent;
