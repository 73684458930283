const status = "pro";
// const status = "dev";
const DevHost = "http://localhost:8888";
const ProHost = "";
const Version = "/v1";
const Message = "/message";
const User = "/user";
const TokenInfo = "/token/info";
const Login = "/login";

function GetHost() {
  return status == "dev" ? DevHost : ProHost;
}

function GetPre() {
  return GetHost() + Version;
}
function UserURL() {
  return GetPre() + User;
}
function MessageURL() {
  return GetPre() + Message;
}
function TokenInfoURL() {
  return GetPre() + TokenInfo;
}
function LoginURL() {
  return GetPre() + Login;
}

module.exports = {
  // Host:"http://localhost:8888",
  // Host: "",
  // Version: "/v1",
  // Message: "/message",
  // User: "/user",
  // TokenInfo: "/token/info",
  // Login: "/login",
  UserURL: UserURL,
  TokenInfoURL: TokenInfoURL,
  LoginURL: LoginURL,
  MessageURL: MessageURL
};
