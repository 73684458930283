import React from "react"
import {Link} from "react-router-dom"
import "./index.css"
class Sign extends React.Component{
  constructor(prop){
    super(prop)
  }
  
  render(){
    return(
      <div className="sign">
        <Link to="/signin">
          <button className="sign-in">登录</button>
        </Link>
        <Link to="/signup">
          <button className="sign-up">注册</button>
          </Link>
      </div>
    )
  }
}

export default Sign