import React from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./Sign.css";
import Url from "./tools/url.js";

class PageSignIn extends React.Component {
  constructor(prop) {
    super(prop);
  }

  login = () => {
    let username = this.refs["username"].value;
    let passwd = this.refs["passwd"].value;
    console.log(username, passwd);
    Axios.post(`${Url.LoginURL()}`, {
      username: username,
      passwd: passwd
    })
      .then(res => {
        alert("登录成功");
        console.log("登录成功 => ", res);

        localStorage.setItem("iris-token", res.data.token);
        console.log("已经将登录获得的token存入localStorage");
        this.props.history.push("");
      })
      .catch(err => {
        alert(`登录失败${JSON.stringify(err.response.data)}`);
        console.log("登录失败 => ", err.response.data);
      });
  };
  render() {
    return (
      <div className="page-sign-in">
        <div className="page-header">登录</div>
        <div className="page-content">
          <div className="input-title">用户名</div>
          <input className="input-area" ref="username"></input>
          <div className="input-title">密码</div>
          <input className="input-area" ref="passwd" type="password"></input>
          <div className="input-button">
            <button className="input-button-ok" onClick={this.login}>
              登录
            </button>
            <Link to="/">
              <button className="input-button-return">返回</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PageSignIn;
