import React from "react";
import Axios from "axios";
import Url from "./tools/url";
import { Link } from "react-router-dom";
import "./Sign.css";

class PageSignUp extends React.Component {
  constructor(prop) {
    super(prop);
  }
  register = () => {
    let username = this.refs["username"].value;
    let passwd = this.refs["passwd"].value;
    let gender = this.refs["gender"].value;
    let age = this.refs["age"].value;
    let interest = this.refs["interest"].value;
    Axios.post(`${Url.UserURL()}`, {
      username: username,
      passwd: passwd,
      gender: gender,
      age: age,
      interest: interest
    })
      .then(res => {
        alert("注册成功");
        console.log("注册成功 => ", res);
        this.props.history.push("/signin");
      })
      .catch(err => {
        alert(`注册失败${JSON.stringify(err.response.data)}`);
        console.log("注册失败 => ", err.response.data);
      });
  };
  render() {
    return (
      <div className="page-sign-up">
        <div className="page-header">注册</div>
        <div className="page-content">
          <div className="input-title">用户名</div>
          <input className="input-area" ref="username"></input>
          <div className="input-title">密码</div>
          <input className="input-area" ref="passwd"></input>
          <div className="input-title">性别</div>
          <select className="input-area" ref="gender">
            <option value="1">女</option>
            <option value="2">男</option>
          </select>
          <div className="input-title">年龄</div>
          <input className="input-area" ref="age"></input>
          <div className="input-title">兴趣爱好</div>
          <input className="input-area" ref="interest"></input>
          <div className="input-button">
            <button className="input-button-ok" onClick={this.register}>
              注册
            </button>
            <Link to="/">
              <button className="input-button-return">返回</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PageSignUp;
