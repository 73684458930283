import React from "react";
import Axios from "axios";
import logo from "./logo.svg";
import "./App.css";
import MsgContent from "./component/MsgContent";
import SendBox from "./component/SendBox";
import Sign from "./component/SigninOrSignup";
import PageSignIn from "./SignIn";
import PageSignUp from "./SignUp";
import Url from "./tools/url.js";
let isLogin = false;
class App extends React.Component {
  constructor(prop) {
    super(prop);
    console.log(this.props);
    this.state = {
      userInfo: null,
      logged: false,
      msgList: [],
      timerId: 0
    };
    this.isLogged();
  }

  isLogged = () => {
    Axios.get(`${Url.TokenInfoURL()}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("iris-token")}`
      }
    })
      .then(res => {
        console.log("验证登录token成功 =>", res);
        this.setState({
          logged: true,
          userInfo: res.data
        });
        this.refs["MsgContent"].setState({ logged: true });
      })
      .catch(err => {
        console.log("验证登录token失败 => ", err.response.data);
        this.refs["MsgContent"].setState({ logged: false });
        clearTimeout(this.state.timerId);
      });
  };
  componentWillMount = () => {
    console.log("componentWillMount");
    let timerId = setInterval(() => {
      // Axios.get(`${Url.TokenInfoURL()}`, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("iris-token")}`
      //   }
      // })
      //   .then(res => {
      //     console.log("验证登录token成功 =>", res);
      //     this.setState({
      //       logged: true,
      //       userInfo: res.data
      //     });
      //   })
      //   .catch(err => {
      //     console.log("验证登录token失败 => ", err.response.data);
      //     this.refs["MsgContent"].setState({ logged: false });
      //     clearTimeout(timerId);
      //   });
      this.isLogged();
    }, 5000);
    this.setState({
      timerId: timerId
    });
  };
  componentWillUnmount = () => {
    clearTimeout(this.state.timerId);
  };
  render() {
    return (
      <div className="App">
        <div className="app-header">在线聊天室</div>
        <div className="app-content">
          <MsgContent
            history={this.props.history}
            ref="MsgContent"
          ></MsgContent>
        </div>
        <div className="app-bottom">
          {this.state.logged ? (
            <SendBox userInfo={this.state.userInfo} ref="SendBox"></SendBox>
          ) : (
            <Sign></Sign>
          )}
        </div>
      </div>
    );
  }
}
export default App;
