import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';

import App from './App';
import PageSignIn from './SignIn';
import PageSignUp from './SignUp';

const Routes=()=>(
  <Router>
    <Switch>
        <Route path='/signin' component={PageSignIn}/>
        <Route path='/signup' component={PageSignUp}/>
        <Route path='/' component={App}/>
    </Switch>
  </Router>
)

ReactDOM.render(Routes(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
